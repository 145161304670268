@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i|Poppins:400,500,600,700,800|Roboto:300,400,400i,500,500i,700&display=swap');

html {
   font-family: sans-serif;
   text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body,
html {
   height: 100%;
   margin: 0;
   padding: 0;
   font-size: 14px;
   font-weight: 300;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
body {
   background-color: rgb(242, 242, 242);
   display: flex;
   flex-direction: column;
   color: #444;
   font-family: 'Noto Sans', sans-serif;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.025em;
}

/* serve per Fixare i riquadri  Ghost dei tag - vedi issue #95 */
:focus {
   outline: 0px !important;
}

a {
   color: #ec7d05;
}

a:hover,
a:active,
a:focus {
   text-decoration: none !important;
}

a,
button {
   outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
   color: #1e2e62;
   font-family: Poppins, Helvetica, sans-serif;
}
.card-subtitle {
   font-family: 'Noto Sans', sans-serif;
   color: #ec7d05;
   font-size: 90%;
   text-transform: uppercase;
   font-weight: 700;
   letter-spacing: 0;
   margin-top: 10px;
   margin-bottom: 10px;
}
.card-subtitle.text-muted {
   color: #ec7d05 !important;
}
.no-margin {
   margin: 0;
}
.jsflgr {
   flex-grow: 1;
}

.btn-primary {
   color: #fff;
   background-color: rgba(30, 46, 98, 1);
   border-color: #1e2e62;
}

/****** Alert ******/
.MuiAlert-outlinedWarning {
   color: rgb(102, 60, 0);
   border: 2px solid #ff9800 !important;
   font-size: 105% !important;
}
.MuiTypography-body1 {
   font-size: 110% !important;
}

@media (max-width: 600px) {
   .MuiContainer-root {
      padding-left: 1px !important;
      padding-right: 1px !important;
   }
}
