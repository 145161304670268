@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i|Poppins:400,500,600,700,800|Roboto:300,400,400i,500,500i,700&display=swap);
.App-link {
  color: #ec7d05;
}
/** BOX content  **/
.kt-section__content {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
  margin-bottom: 20px;
}
.kt-section__content.card {
  border: 0;
  border-radius: 0;
}
/** Titolo pagina  **/
/** Breadcrumb **/
.kt-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.kt-container, .kt-container.kt-container--fluid {
  width: 100%;
  margin: 15px 0;
}
.kt-subheader__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: .25rem 0;
}
.kt-subheader__main .kt-subheader__title {
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.kt-subheader__main .kt-subheader__breadcrumbs {
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  padding-top: .25rem;
}
.kt-subheader__breadcrumbs-link {
  color: #59648B;
}
.kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator {
  display: flex;
  justify-content: content;
  align-items: center;
  padding: 0 .55rem 0 0;
}
.kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: " ";
  background: #59648B;
}

.right {
  float: right;
}
.eval {
  margin-top: 8px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3NyYy9zYXNzL21haW4uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHQTtFQUNFLHFCQUFxQixFQUFBO0VBQ3JCO0lBQ0UsV0FOWTtJQU9aLFlBUFk7SUFRWixrQkFBa0I7SUFDbEIsaUJBVFksRUFBQTs7QUFhaEI7RUFDRSxxQkFBcUI7RUFDckIsZUFBZTtFQUNmLDBCQUEwQjtFQUMxQixnQkFBZ0I7RUFDaEIsWUFBWSxFQUFBOztBQUdkO0VBQ0UscUJBQXFCO0VBQ3JCLGVBQWU7RUFDZixXQUFXO0VBQ1gsZ0JBQWdCO0VBQ2hCLDZCQUE2QjtFQUM3QixnQkFBZ0I7RUFDaEIsY0FBYztFQUNkLGtCQUFrQixFQUFBO0VBRWxCO0lBQ0UsWUFBWTtJQUNaLG1CQUFtQjtJQUNuQixtQkFBbUIsRUFBQTtFQUdyQjtJQUNFLDBCQUEwQjtJQUMxQixZQUFZLEVBQUE7O0FBSWhCO0VBQ0Usb0JBQW9CO0VBQ3BCLFlBQVk7RUFDWixXQUFXLEVBQUEiLCJmaWxlIjoibWFpbi5jc3MifQ== */
/******** Material-UI : Login ***********/
.MuiContainer-root {
   text-align: center;
}
.MuiContainer-root h1 {
   margin-top: 0.8em;
   margin-bottom: 0.8em;
}

.MuiContainer-root .icon-elements {
   max-height: 88px;
   margin-top: 1em;
}

.MuiFormLabel-root.Mui-focused {
   color: #1e2e62 !important;
}
input:-internal-autofill-selected {
   background-color: rgba(184, 185, 174, 0.3) !important;
   color: -internal-light-dark-color(#1e2e62, white) !important;
}
.MuiInputBase-input {
   background: rgba(255, 255, 255, 0.55) !important;
}
.MuiInputBase-input::selection {
   background: rgba(110, 197, 43, 0.55) !important;
}
.MuiButton-root {
   padding: 7px 16px !important;
   font-size: 0.985rem !important;
}

.MuiPaper-root.ebs-card {
   padding: 35px 8%;
   border-top: 5px solid #f37014;
}
.square-elements {
   max-height: 25px;
   display: block;
}

.MuiButton-root {
   margin-top: 18px !important;
   font-size: 16px !important;
   line-height: 1.42857143 !important;

   border: 0 !important;
   border-radius: 0 !important;
   padding: 1em 2.2em 1em !important;
   text-decoration: none;
   transition: all 0.3s ease-out !important;
}

@media (max-width: 600px) {
   .MuiContainer-root .icon-elements {
      max-height: 55px;
      margin-top: 0.4em;
   }
   .MuiPaper-root.ebs-card {
      padding: 15px 4%;
      border-top: 5px solid #59648b;
   }
}

/* react-simple-tree-menu
  non è possibile importare files al di fuori della cartella src ( dipende da come è impostato il processo di build )
  quindi lo copio qui dentro. Path originale /node_modules/react-simple-tree-menu/dist/main.css
*/
.rstm-toggle-icon {
   display: inline-block;
}
.rstm-toggle-icon-symbol {
   width: 2rem;
   height: 2rem;
   text-align: center;
   line-height: 2rem;
}

.rstm-tree-item-group {
   list-style-type: none;
   padding-left: 0;
   border-top: 1px solid #ccc;
   text-align: left;
   width: 100%;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 5px 0 #222;
   z-index: 999;
}
.rstm-search {
   padding: 1rem 1.5rem;
   border: none;
   width: 100%;
}
.rstm-tree-item--active {
   color: white;
   background: #59648b;
   border-bottom: none;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
   text-align: center;
   flex: 0 0 10px;
   margin-right: 10px;
}
.rstm-toggle-icon-symbol {
   width: 1.8rem;
   height: 1.8rem;
   text-align: center;
   line-height: 1.8rem;
   margin-right: 0px;
}
.rstm-tree-item-group > .rstm-tree-item {
   /*display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;*/
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   transition: all 0.3s;
   color: #444;

   width: 100%;
}

.rstm-tree-item-group > .rstm-tree-item {
   display: flex;
   flex-grow: 1;
   align-items: center;
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   transition: all 0.3s;
   color: #444;
}
.rstm-tree-item--active {
   color: #1e2e62;
   background: #fbfbfb;
   border-bottom: none;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 1px 0 #b8b9ae;
}
.rstm-toggle-icon-symbol {
   font-weight: bold;
   font-size: 18px;
}
.kt-section__content ul {
   list-style: none;
   padding-left: 0.55rem;
   padding-bottom: 0.55rem;
}
.kt-section__content .containItem {
   position: relative;
   display: list-item;
   display: flex;
   justify-content: space-between;
   align-items: stretch;
   padding-top: 0.2em;
   padding-bottom: 0.2em;
   border-bottom: 1px solid #f7f8fa;
   align-items: center;
}
.kt-section__content .containItem-tag {
   padding-bottom: 1.85rem;
}
.rstm-tree-item {
   display: flex;
   flex-grow: 1;
   align-items: center;
   padding: 0.15rem 1rem;
   cursor: pointer;
   transition: all 0.3s;
   /*clear: both;*/
}

.iconStato {
   display: flex;
   align-items: center;
   margin-right: 0.55rem;
}

.iconStato.error {
   color: #ff3b30;
}
.iconStato.success {
   color: #5cb85c;
}
.iconStato.warning {
   color: #fcb80c;
}

.react-tooltip-lite {
   background: #59648b;
   color: white;
   max-width: 240px !important;
}

.react-tooltip-lite-arrow {
   border-color: #59648b;
}

.rstm-tree-item-group {
   list-style-type: none;
   padding-left: 0;
   border-top: 1px solid #ccc;
   text-align: left;
   width: 100%;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 5px 0 #222;
   z-index: 999;
}
.rstm-search {
   padding: 1rem 1.5rem;
   border: none;
   width: 100%;
}
.rstm-tree-item--active {
   color: white;
   background: #59648b;
   border-bottom: none;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
   text-align: center;
   flex: 0 0 10px;
   margin-right: 10px;
}
.rstm-toggle-icon-symbol {
   width: 1.8rem;
   height: 1.8rem;
   text-align: center;
   line-height: 1.8rem;
   margin-right: 0px;
}
.rstm-tree-item-group > .rstm-tree-item {
   display: flex;
   flex-grow: 1;
   align-items: center;
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   transition: all 0.3s;
   color: #444;
}
.rstm-tree-item--active {
   color: #1e2e62;
   background: #fbfbfb;
   border-bottom: none;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 1px 0 #b8b9ae;
}

@media (min-width: 768px) and (max-width: 1200px) {
   .md-pr-5 {
      padding-right: 5px !important;
   }

   .md-pl-5 {
      padding-left: 5px !important;
   }
}

.input-group-prepend .btn {
   border-radius: 0;
}
.has-search .form-control {
   padding-left: 2.35rem;
   border: 1px solid #1e2e62;
   border-radius: 0;
}
.has-search .form-control-feedback {
   position: absolute;
   z-index: 50;
   display: block;
   width: 2.35rem;
   text-align: center;
   pointer-events: none;
   color: grey;
}

#tag {
   display: block;
   width: 100%;
   text-align: right;
   margin-right: 0.75rem;
   margin-top: -2.15rem;
}
#tag ul {
   list-style: none;
   padding-right: 0.75rem;
}
#tag li {
   display: inline;
   padding-left: 0.35rem;
}

/******** Material-UI : CARD ***********/
.card-text {
   text-align: justify;
   font-size: 95%;
   margin-bottom: 1.75rem;
}

/******** Material-UI : BottomNavigation ***********/
.MuiToggleButton-root {
   color: #1e2e62;
   border: 1px solid rgba(0, 0, 0, 0.5);
   height: 48px;
   padding: 0px 12px;
   font-size: 0.975rem;
}
.MuiBottomNavigationAction-root {
   color: #1e2e62 !important;
}

/******** Material-UI : ToggleButton ***********/
.MuiToggleButton-root {
   color: rgba(0, 0, 0, 0.65) !important;
   border: 1px solid #999 !important;
   height: 48px;
   padding: 0px 12px;
   font-size: 0.875rem;
   font-weight: bold !important;
}
.MuiButton-sizeSmall {
   padding: 2px 11px !important;
}

.MuiBox-root-103 {
   margin-bottom: 10px !important;
}

.MuiBottomNavigationAction-label {
   font-size: 0.85rem !important;
}
.col-height {
   height: 100%;
}

/******** Badge version ***********/
.badge-primary {
   color: #fff;
   background-color: #ec7d05 !important;
}

.alert-saved.MuiAlert-root {
   margin-bottom: 8px !important;
   padding: 0px 12px !important;
   font-size: 0.95rem !important;
   margin-top: 8px !important;
   font-weight: bold;
}

/* display this row with flex and use wrap (= respect columns' widths) */

.row-flex {
   display: flex;
   flex-wrap: wrap;
}

/* vertical spacing between columns */

[class*='col-'] {
   margin-bottom: 30px;
}

.content {
   height: 100%;
   padding: 20px 20px 10px;
   color: #fff;
}

/* Demo backgrounds and styling*/

.colour-1 {
   background: #483c46;
   color: #fff;
}

.colour-2 {
   background: #3c6e71;
}

.colour-3 {
   background: #70ae6e;
}

.colour-4 {
   background: #82204a;
}

.colour-5 {
   background: #558c8c;
}

.colour-6 {
   background: #917c78;
}

body {
   padding: 20px 0;
   font-family: Roboto, sans-serif;
}

.content h3 {
   margin-top: 0px;
   font-weight: 300;
}

h1 {
   font-weight: 300;
   margin-bottom: 40px;
}

.bg-white {
   background-color: #fff !important;
   border-top: 5px solid #f37014;
}/*
.App-header > .bg-light {
   background-color: transparent !important;
}*/

.navbar .icon-elements {
   position: absolute;
   left: 104px;
   top: 20px;
   max-height: 33px;
   z-index: -1;
}
/** Menu di navigazione **/

@media (min-width: 992px) {
   .App-header {
      align-items: stretch;
      transition: height 0.3s ease;
      position: relative;
      z-index: 2;
      height: 70px;
      margin-bottom: 10px;
      top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
   }

   .navbar {
      width: 100%;
   }
   .kt_header_menu {
      display: flex;
      align-items: stretch;
      height: 100%;
      width: 40%;
      margin: 0;
      flex-grow: 1;
      align-items: center;
   }
   .kt-menu__nav {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-left: 60px;
      display: flex;
      align-items: stretch;
      height: 100%;
      margin: 0;
      list-style: none;
   }
   .kt-menu__nav .kt-menu__item {
      display: flex;
      align-items: center;
      padding: 0 0.15rem;
   }
   .kt-menu__nav .kt-menu__item a {
      padding: 0.75rem 1.25rem;
      border-radius: 4px;
      background-color: none;
      transition: all 0.3s ease;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
   }
}

.kt-menu__nav .kt-menu__item a {
   font-family: 'Noto Sans', sans-serif;
   color: #1e2e62;
   font-weight: 700;
   font-size: 13px;
   text-transform: uppercase;
}

@media (max-width: 991px) {
   .navbar-brand {
      margin-bottom: 8px;
   }

   .kt-menu__nav .kt-menu__item a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      line-height: 2.6em;
      border-bottom: 1px solid #ddd;
   }
   .kt-menu__nav .kt-menu__item:first-child {
      border-top: 1px solid #ddd;
   }
   .kt-menu__nav {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
   }
   .dropdown {
      text-align: right;
   }
   .dropdown-toggle::after {
      display: none !important;
   }
}

@media (max-width: 1399px) and (min-width: 1025px) {
   .kt-menu__nav > .kt-menu__item > a {
      padding: 0.75rem;
      color: #1e2e62;
   }
}

/***** hamburger menu ****/

.navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(89, 100, 139, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
.navbar-toggler {
   border-color: rgba(89, 100, 139, 0.7) !important;
   border-radius: 0 !important;
   padding: 0.25rem 0.35rem !important;
}

.dropdown-menu {
    right: 0 !important;
    left: auto !important;
}
html {
   font-family: sans-serif;
   -moz-text-size-adjust: 100%;
        text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body,
html {
   height: 100%;
   margin: 0;
   padding: 0;
   font-size: 14px;
   font-weight: 300;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
body {
   background-color: rgb(242, 242, 242);
   display: flex;
   flex-direction: column;
   color: #444;
   font-family: 'Noto Sans', sans-serif;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.025em;
}

/* serve per Fixare i riquadri  Ghost dei tag - vedi issue #95 */
:focus {
   outline: 0px !important;
}

a {
   color: #ec7d05;
}

a:hover,
a:active,
a:focus {
   text-decoration: none !important;
}

a,
button {
   outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
   color: #1e2e62;
   font-family: Poppins, Helvetica, sans-serif;
}
.card-subtitle {
   font-family: 'Noto Sans', sans-serif;
   color: #ec7d05;
   font-size: 90%;
   text-transform: uppercase;
   font-weight: 700;
   letter-spacing: 0;
   margin-top: 10px;
   margin-bottom: 10px;
}
.card-subtitle.text-muted {
   color: #ec7d05 !important;
}
.no-margin {
   margin: 0;
}
.jsflgr {
   flex-grow: 1;
}

.btn-primary {
   color: #fff;
   background-color: rgba(30, 46, 98, 1);
   border-color: #1e2e62;
}

/****** Alert ******/
.MuiAlert-outlinedWarning {
   color: rgb(102, 60, 0);
   border: 2px solid #ff9800 !important;
   font-size: 105% !important;
}
.MuiTypography-body1 {
   font-size: 110% !important;
}

@media (max-width: 600px) {
   .MuiContainer-root {
      padding-left: 1px !important;
      padding-right: 1px !important;
   }
}

