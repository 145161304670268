/******** Material-UI : Login ***********/
.MuiContainer-root {
   text-align: center;
}
.MuiContainer-root h1 {
   margin-top: 0.8em;
   margin-bottom: 0.8em;
}

.MuiContainer-root .icon-elements {
   max-height: 88px;
   margin-top: 1em;
}

.MuiFormLabel-root.Mui-focused {
   color: #1e2e62 !important;
}
input:-internal-autofill-selected {
   background-color: rgba(184, 185, 174, 0.3) !important;
   color: -internal-light-dark-color(#1e2e62, white) !important;
}
.MuiInputBase-input {
   background: rgba(255, 255, 255, 0.55) !important;
}
.MuiInputBase-input::selection {
   background: rgba(110, 197, 43, 0.55) !important;
}
.MuiButton-root {
   padding: 7px 16px !important;
   font-size: 0.985rem !important;
}

.MuiPaper-root.ebs-card {
   padding: 35px 8%;
   border-top: 5px solid #f37014;
}
.square-elements {
   max-height: 25px;
   display: block;
}

.MuiButton-root {
   margin-top: 18px !important;
   font-size: 16px !important;
   line-height: 1.42857143 !important;

   border: 0 !important;
   border-radius: 0 !important;
   padding: 1em 2.2em 1em !important;
   text-decoration: none;
   -webkit-transition: all 0.3s ease-out !important;
   transition: all 0.3s ease-out !important;
}

@media (max-width: 600px) {
   .MuiContainer-root .icon-elements {
      max-height: 55px;
      margin-top: 0.4em;
   }
   .MuiPaper-root.ebs-card {
      padding: 15px 4%;
      border-top: 5px solid #59648b;
   }
}
