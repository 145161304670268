/******** Material-UI : CARD ***********/
.card-text {
   text-align: justify;
   font-size: 95%;
   margin-bottom: 1.75rem;
}

/******** Material-UI : BottomNavigation ***********/
.MuiToggleButton-root {
   color: #1e2e62;
   border: 1px solid rgba(0, 0, 0, 0.5);
   height: 48px;
   padding: 0px 12px;
   font-size: 0.975rem;
}
.MuiBottomNavigationAction-root {
   color: #1e2e62 !important;
}

/******** Material-UI : ToggleButton ***********/
.MuiToggleButton-root {
   color: rgba(0, 0, 0, 0.65) !important;
   border: 1px solid #999 !important;
   height: 48px;
   padding: 0px 12px;
   font-size: 0.875rem;
   font-weight: bold !important;
}
.MuiButton-sizeSmall {
   padding: 2px 11px !important;
}

.MuiBox-root-103 {
   margin-bottom: 10px !important;
}

.MuiBottomNavigationAction-label {
   font-size: 0.85rem !important;
}
.col-height {
   height: 100%;
}

/******** Badge version ***********/
.badge-primary {
   color: #fff;
   background-color: #ec7d05 !important;
}

.alert-saved.MuiAlert-root {
   margin-bottom: 8px !important;
   padding: 0px 12px !important;
   font-size: 0.95rem !important;
   margin-top: 8px !important;
   font-weight: bold;
}

/* display this row with flex and use wrap (= respect columns' widths) */

.row-flex {
   display: flex;
   flex-wrap: wrap;
}

/* vertical spacing between columns */

[class*='col-'] {
   margin-bottom: 30px;
}

.content {
   height: 100%;
   padding: 20px 20px 10px;
   color: #fff;
}

/* Demo backgrounds and styling*/

.colour-1 {
   background: #483c46;
   color: #fff;
}

.colour-2 {
   background: #3c6e71;
}

.colour-3 {
   background: #70ae6e;
}

.colour-4 {
   background: #82204a;
}

.colour-5 {
   background: #558c8c;
}

.colour-6 {
   background: #917c78;
}

body {
   padding: 20px 0;
   font-family: Roboto, sans-serif;
}

.content h3 {
   margin-top: 0px;
   font-weight: 300;
}

h1 {
   font-weight: 300;
   margin-bottom: 40px;
}
