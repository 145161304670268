/* react-simple-tree-menu
  non è possibile importare files al di fuori della cartella src ( dipende da come è impostato il processo di build )
  quindi lo copio qui dentro. Path originale /node_modules/react-simple-tree-menu/dist/main.css
*/
.rstm-toggle-icon {
   display: inline-block;
}
.rstm-toggle-icon-symbol {
   width: 2rem;
   height: 2rem;
   text-align: center;
   line-height: 2rem;
}

.rstm-tree-item-group {
   list-style-type: none;
   padding-left: 0;
   border-top: 1px solid #ccc;
   text-align: left;
   width: 100%;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 5px 0 #222;
   z-index: 999;
}
.rstm-search {
   padding: 1rem 1.5rem;
   border: none;
   width: 100%;
}
.rstm-tree-item--active {
   color: white;
   background: #59648b;
   border-bottom: none;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
   text-align: center;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 10px;
   flex: 0 0 10px;
   margin-right: 10px;
}
.rstm-toggle-icon-symbol {
   width: 1.8rem;
   height: 1.8rem;
   text-align: center;
   line-height: 1.8rem;
   margin-right: 0px;
}
.rstm-tree-item-group > .rstm-tree-item {
   /*display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;*/
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
   color: #444;

   width: 100%;
}

.rstm-tree-item-group > .rstm-tree-item {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
   color: #444;
}
.rstm-tree-item--active {
   color: #1e2e62;
   background: #fbfbfb;
   border-bottom: none;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 1px 0 #b8b9ae;
}
.rstm-toggle-icon-symbol {
   font-weight: bold;
   font-size: 18px;
}
.kt-section__content ul {
   list-style: none;
   padding-left: 0.55rem;
   padding-bottom: 0.55rem;
}
.kt-section__content .containItem {
   position: relative;
   display: list-item;
   display: flex;
   justify-content: space-between;
   align-items: stretch;
   padding-top: 0.2em;
   padding-bottom: 0.2em;
   border-bottom: 1px solid #f7f8fa;
   align-items: center;
}
.kt-section__content .containItem-tag {
   padding-bottom: 1.85rem;
}
.rstm-tree-item {
   display: flex;
   flex-grow: 1;
   align-items: center;
   padding: 0.15rem 1rem;
   cursor: pointer;
   transition: all 0.3s;
   /*clear: both;*/
}

.iconStato {
   display: flex;
   align-items: center;
   margin-right: 0.55rem;
}

.iconStato.error {
   color: #ff3b30;
}
.iconStato.success {
   color: #5cb85c;
}
.iconStato.warning {
   color: #fcb80c;
}

.react-tooltip-lite {
   background: #59648b;
   color: white;
   max-width: 240px !important;
}

.react-tooltip-lite-arrow {
   border-color: #59648b;
}

.rstm-tree-item-group {
   list-style-type: none;
   padding-left: 0;
   border-top: 1px solid #ccc;
   text-align: left;
   width: 100%;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 5px 0 #222;
   z-index: 999;
}
.rstm-search {
   padding: 1rem 1.5rem;
   border: none;
   width: 100%;
}
.rstm-tree-item--active {
   color: white;
   background: #59648b;
   border-bottom: none;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
   text-align: center;
   -webkit-box-flex: 0;
   -ms-flex: 0 0 10px;
   flex: 0 0 10px;
   margin-right: 10px;
}
.rstm-toggle-icon-symbol {
   width: 1.8rem;
   height: 1.8rem;
   text-align: center;
   line-height: 1.8rem;
   margin-right: 0px;
}
.rstm-tree-item-group > .rstm-tree-item {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-flex: 1;
   -ms-flex-positive: 1;
   flex-grow: 1;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   padding: 0.55rem 1.55rem;
   cursor: pointer;
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
   color: #444;
}
.rstm-tree-item--active {
   color: #1e2e62;
   background: #fbfbfb;
   border-bottom: none;
}
.rstm-tree-item--focused {
   box-shadow: 0 0 1px 0 #b8b9ae;
}

@media (min-width: 768px) and (max-width: 1200px) {
   .md-pr-5 {
      padding-right: 5px !important;
   }

   .md-pl-5 {
      padding-left: 5px !important;
   }
}

.input-group-prepend .btn {
   border-radius: 0;
}
.has-search .form-control {
   padding-left: 2.35rem;
   border: 1px solid #1e2e62;
   border-radius: 0;
}
.has-search .form-control-feedback {
   position: absolute;
   z-index: 50;
   display: block;
   width: 2.35rem;
   text-align: center;
   pointer-events: none;
   color: grey;
}

#tag {
   display: block;
   width: 100%;
   text-align: right;
   margin-right: 0.75rem;
   margin-top: -2.15rem;
}
#tag ul {
   list-style: none;
   padding-right: 0.75rem;
}
#tag li {
   display: inline;
   padding-left: 0.35rem;
}
