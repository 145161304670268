.bg-white {
   background-color: #fff !important;
   border-top: 5px solid #f37014;
}/*
.App-header > .bg-light {
   background-color: transparent !important;
}*/

.navbar .icon-elements {
   position: absolute;
   left: 104px;
   top: 20px;
   max-height: 33px;
   z-index: -1;
}
/** Menu di navigazione **/

@media (min-width: 992px) {
   .App-header {
      align-items: stretch;
      -webkit-transition: height 0.3s ease;
      transition: height 0.3s ease;
      position: relative;
      z-index: 2;
      height: 70px;
      margin-bottom: 10px;
      top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
   }

   .navbar {
      width: 100%;
   }
   .kt_header_menu {
      display: flex;
      align-items: stretch;
      height: 100%;
      width: 40%;
      margin: 0;
      flex-grow: 1;
      align-items: center;
   }
   .kt-menu__nav {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-left: 60px;
      display: flex;
      align-items: stretch;
      height: 100%;
      margin: 0;
      list-style: none;
   }
   .kt-menu__nav .kt-menu__item {
      display: flex;
      align-items: center;
      padding: 0 0.15rem;
   }
   .kt-menu__nav .kt-menu__item a {
      padding: 0.75rem 1.25rem;
      border-radius: 4px;
      background-color: none;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
   }
}

.kt-menu__nav .kt-menu__item a {
   font-family: 'Noto Sans', sans-serif;
   color: #1e2e62;
   font-weight: 700;
   font-size: 13px;
   text-transform: uppercase;
}

@media (max-width: 991px) {
   .navbar-brand {
      margin-bottom: 8px;
   }

   .kt-menu__nav .kt-menu__item a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      line-height: 2.6em;
      border-bottom: 1px solid #ddd;
   }
   .kt-menu__nav .kt-menu__item:first-child {
      border-top: 1px solid #ddd;
   }
   .kt-menu__nav {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
   }
   .dropdown {
      text-align: right;
   }
   .dropdown-toggle::after {
      display: none !important;
   }
}

@media (max-width: 1399px) and (min-width: 1025px) {
   .kt-menu__nav > .kt-menu__item > a {
      padding: 0.75rem;
      color: #1e2e62;
   }
}

/***** hamburger menu ****/

.navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(89, 100, 139, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
.navbar-toggler {
   border-color: rgba(89, 100, 139, 0.7) !important;
   border-radius: 0 !important;
   padding: 0.25rem 0.35rem !important;
}
